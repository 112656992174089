import React, { useState, useEffect } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";

import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import FormModal from "../../Components/Home/FormModal";

const ITFresher = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalData(null);
    setModalOpen(false);
  };





  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  const [isVisible, setIsVisible] = useState(false);

  const onVisibilityChange = (visible) => {
    if (visible) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);


  const pdfPaths = ["/assets/images/brochure.pdf"];

  const downloadPDF = (pdfPaths) => {
    pdfPaths.forEach((pdfPath) => {
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = pdfPath.split("/").pop();
      link.click();
    });
  };

  return (

    <>
      <Helmet>

        <meta name="title" content={metaTitle} />
        {/* Remaining meta tag */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* scipt for facebook */}
        {helmetContent.script}
        <script>
          {`
          (function (b, m, h, a, g) {
            b[a] = b[a] || [];
            b[a].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var k = m.getElementsByTagName(h)[0],
              e = m.createElement(h),
              c = a != "dataLayer" ? "&l=" + a : "";
            e.async = true;
            e.src = "https://www.googletagmanager.com/gtm.js?id=" + g + c;
            k.parentNode.insertBefore(e, k);
          })(window, document, "script", "dataLayer", "GTM-TN6B4BK");
          `
          }
        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,

        <script>
          {
            `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments) 
      } gtag("js", new Date()); gtag("config", "UA-143016865-1");
       `}
        </script>,

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,

        <script>
          {`

        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-BSFPPN5E9D');
        `}

        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,

        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date());

      gtag('config', 'AW-574047969');
      `}

        </script>

      </Helmet>
      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div>
          <div className="technical-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000">
                <h1 className="text-2xl md:text-6xl font-semibold mb-4 ">
                  IT Freshers <br />
                  <p className="md:text-xl mb-8">
                    Upgrade your Fresher Status as a Working Professional
                  </p>

                </h1>
                {/* <Link to="/contact">
                                <button className="btn-style-2 px-6 py-2 ">
                                    Get In Touch With Us
                                </button>
                            </Link> */}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center pt-14  fade-in-down px-6">
          <h1 className="text-4xl font-bold container mx-auto text-center">
            Internship Program
          </h1>
        </div>

        {/* Student Developer Program */}
        {/* <div className=" bg-gray-100">
          <div className="container  mx-auto flex flex-col justify-center px-2 py-20"
            data-aos="fade-up"
            data-aos-duration="1000">
            <h1 className="text-xl font-bold">
              {" "}
              Eligibility for the Current Openings:
            </h1>
           
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              No criteria, any qualification/branch/passout year
            </p>
            <p className="mt-4 md:w-[80%]  mb-2 font-semibold text-lg">
              Selection Process
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Get Registered; Clear Our interview And Get Hired in ThinkQuotient
            </p>
            

            <p className="mt-4 md:w-[80%] mb-2 font-semibold text-lg">Benefits</p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Your Fresher tag will be removed
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              1 Year Valid Experience as a Junior Developer
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Live Project for Hands-On experience
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Joining + Offer letter from Day-1 + Salary from 2nd month +
              Experience & Relieving letter For the Employment Period
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              In-depth Technical training + Soft-skills grooming
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Working available in Hybrid Mode
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Get trained with our Industry Expert Trainers and Real-time
              Developers
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Multiple technologies: Backend - Dot net Full Stack, Java, Spring
              Boot, Hibernate, JPA, MySQL, Oracle Frontend - HTML, CSS, Angular,
              and repositories like GitHub, QA Manual and Automation
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Unlimited Placement Drives During Program
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              After Placement 1-Year Job Support Provided
            </p>
            <div className="w-full flex justify-center">

              <button className="btn-style-3 px-6 py-2 mt-8 mr-4" onClick={() => openModal("Student Developer Program")}>
                Upload Resume →
              </button>

           
            </div>
            <FormModal isOpen={isModalOpen} onRequestClose={closeModal} initialData={modalData} />
          </div>
        </div> */}

        {/* <div className="w-full ImageDesc"
          data-aos="fade-left"
          data-aos-duration="1000"
          id="web-development"
        >
          
          <img
            src="/assets/images/internnew.png"
            className="object-cover h-full w-full p-10"
          />
           <img
            src="/assets/images/internp.png"
            className="object-cover h-full w-full p-10"
          />
        </div> */}
           <div className="m-0 md:flex service-boxes ">
    <div className="md:w-1/2 p-4" 
        data-aos="fade-right"
        data-aos-duration="1000"
        id="web-development"
    >
        <img
            src="/assets/images/internp.png"
            className="object-cover h-full w-full"
            alt="Training Program"
        />
    </div>
    <div className="md:w-1/2 p-4 itpython"
        data-aos="fade-left" 
        data-aos-duration="1000"
        id="web-development"
    >
        <img
           src="/assets/images/internnew.png"
            className="object-cover h-full w-full"
               alt="Training Program"
        />
    </div>
</div>




        {/* <div className="w-full ImagePhone"
          data-aos="fade-left"
          data-aos-duration="1000"
          id="web-development"
        >
          
          <img
            src="/assets/images/internp.png"
            className="object-cover h-full w-full"
          />
        </div> */}
        <div className="justify-center text-center m-5">
          <Link to={'/contact?flag=INTERNSHIP' }>
            <button
              type="submit"
              className="btn-style-3 sm:px-6 align-center sm:py-2 rounded border border-primary bg-primary p-3 text-dark transition hover:bg-opacity-90"
            >
              Click here to know more
            </button>
          </Link>
        </div>

        <div className="top-footer py-10 mb-10 block md:flex justify-evenly items-center overflow-hidden">
          <div data-aos="fade-right" data-aos-duration="1000"
          >
            <h1 className="md:text-3xl md:text-left text-white font-bold  text-center">
              Drive Assistance Developer Program
            </h1>
          </div>
          <div
            className="flex  justify-center mt-6 md:mt-0"
            data-aos="fade-left"
            data-aos-duration="1000"

          >



            <button className="btn-style-3 px-6 py-2  sm:px-6 sm:py-2" onClick={() => downloadPDF(pdfPaths)}> <b>Download Brochure</b>  </button>

          </div>
        </div>


        <TestimonialsandBlogSec />



      

        <div>
          {/* <VisibilitySensor onChange={onVisibilityChange}>
            <div className="flex flex-col items-center py-14 why-us-sec ">
              <h1 className="text-2xl font-bold md:mb-14 mb-8">Why Us ?</h1>

              <div
                className="md:flex space-y-10 md:space-y-0 justify-between md:w-2/3"

              >
                <div className="counter-item text-center">
                  <h3 className="text-3xl font-bold mb-2">
                    {isVisible && <CountUp end={1700} duration={4} />}
                    <span className="text-secondary-color">+</span>
                  </h3>
                  <p>Student Placed</p>
                </div>

                <div className="counter-item text-center">
                  <h3 className="text-3xl font-bold mb-2">
                    {isVisible && <CountUp end={100} duration={4} />}
                    <span className="text-secondary-color">+ Years</span>
                  </h3>
                  <p>Collective IT Experience</p>
                </div>

                <div className="counter-item text-center">
                  <h3 className="text-3xl font-bold mb-2">
                    {isVisible && <CountUp end={400} duration={4} />}
                    <span className="text-secondary-color">+</span>
                  </h3>
                  <p>Corporate Tie Ups</p>
                </div>
              </div>
            </div>
          </VisibilitySensor> */}
        </div>


        {/* end why us */}
        <ClientLogoCarousel />

        <Footer />
      </div>
    </>
  );
};

export default ITFresher;
